.manage-project{
    background-color:#007bff;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    border: none;
}
.edit-icon {
  width: 30px; 
  height: 24px; 
  margin-right: 5px;
   cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}
.edit-icon:hover {
  filter: grayscale(100%);
  background-color: #f2f2f2;
}

.action-icon {
  display: flex;
  align-items: center;
   margin-right: 4px; 
}

.action-icon button {
  margin-right: 3px; 
}
.button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: fit-content;
}

.button:hover {
  background-color: #0056b3;
}