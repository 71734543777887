.req-form-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-left: 300px;
}
.req-form{
    margin-top: -100px;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 20px;
    width: 350px;
    background-color: #f2f2f2;
}
.heading{
    display: flex;
    justify-content: center;
    align-items: center;
}
.heading>h2{
    font-weight: bold;
    font-size: 18px;
}
.req-item{
    margin: 10px;
}
.req-item>input{
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    padding: 4px;
}
.req-item #req-dropdown{
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 5px;
    padding: 6px;
}
.req-item>label{
    display: block;
    font-weight: 500;
}
.req-submit{
    display: flex;
    justify-content: space-around;
}
.req-cancel{
    background-color: rgba(234, 6, 6, 0.762);
    padding: 4px;
    color: white;
    border-radius: 5px;
}
.req-confirm{
    padding: 4px;
    background-color: #007bff;
    border-radius: 5px;
    color: white;
}