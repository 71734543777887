            .Navbar-left {
                display: inline;
                position: fixed;
                left: 0;
                width: 60px;
                height: 100%;
                background-color: #003059;
                margin: 0;
                padding: 0;
                transition: height 0.3s ease;
            }
            
            .Navbar-left a {
                display: block;
                padding: 12px 16px;
                text-decoration: none;
                color: #333;
            }
            
            .nav-item {
                list-style-type: none;
                margin-bottom: 10px;
            }
            
            .nav {
                display: flex;
                flex-direction: column;
            }
            
            .logo {
                filter: invert(100%);
                height: 70%;
                width: 70%;
            }
            
            .bar {
                margin-top: 10px;
                margin-left: 18px;
            }
            
            .bar>i {
                display: none;
                color: white;
            }
            
            .navlink {
                padding: 0.2rem 0.5rem;
                text-decoration: none;
                border-radius: 10px;
                transition: background-color 0.3s ease;
            }
            
            .navlink:hover {
                background-color: #c3c1c1;
            }
            
            .nav-item-log {
                align-self: flex-end;
                justify-self: flex-end;
            }
            
            .close {
                color: white;
                float: right;
                margin-top: 10px;
                display: none;
            }
            
            .avatar-container {
                position: absolute;
                width: 57px;
                height: 57px;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                bottom: 0;
                margin: 30px 1px;
            }
            
            .avatar {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                cursor: pointer;
            }
            
            .avatar-options {
                position: absolute;
                bottom: 0;
                left: 60px;
                background-color: whitesmoke;
                border-radius: 4px;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                padding: 4px 8px;
                z-index: 100;
                display: none;
                margin-top: -4px;
                white-space: nowrap;
            }
            
            .avatar-container:hover {
                border-radius: 50%;
            }
            
            .avatar-container.active {
                background-color: gray;
                border-radius: 50%;
            }
            
            .avatar-options.active {
                display: block;
                --options-spacing-x: 20px;
                --options-spacing-y: 20px;
            }
            
            .avatar-option {
                display: block;
                text-decoration: none;
                color: #003059;
                padding: 2px 4px;
            }
            
            .profile-info {
                color: #fff;
            }
            
            .signout-img {
                display: inline-block;
                height: 15px;
                width: 15px;
                margin-right: 10px;
            }
            
            .subho-img {
                display: inline-block;
                height: 20px;
                width: 20px;
                margin-right: 10px;
                margin-left: -3px;
            }
            
            @media (max-width: 768px) {
                .Navbar-left {
                    display: inline;
                    position: fixed;
                    width: 100%;
                    height: 60px;
                    margin: 0;
                    padding: 0;
                }
                .nav {
                    margin-top: 60px;
                    display: none;
                }
                .nav.active {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #003059;
                }
                .close.active {
                    display: block;
                    margin-top: 20px;
                    margin-right: 25px;
                }
                .Navbar-left a {
                    display: inline-block;
                    padding: 12px 16px;
                    text-decoration: none;
                    color: #333;
                }
                .logo {
                    filter: invert(100%);
                    height: 70%;
                    width: 20px;
                }
                .bar>i {
                    display: block;
                    margin-top: 20px;
                    float: right;
                    margin-right: 20px;
                    cursor: pointer;
                }
                .bar.active {
                    display: none;
                }
                .nav-item-log {
                    align-self: flex-end;
                    justify-self: flex-end;
                }
                .navlink {
                    padding: 0.2rem 0.5rem;
                    text-decoration: none;
                    border-radius: 10px;
                    transition: background-color 0.3s ease;
                }
            }
            
            .Navbar-left .nav .navlink {
                position: relative;
            }
            
            .avatar-container .field {
                text-align: left;
                padding: 5px;
                margin: 0;
            }
            
            .avatar-container .btn {
                text-align: left;
                padding: 0px;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: start;
                cursor: pointer;
            }
            
            .Navbar-left .nav .navlink .icon-name {
                height: 30px;
                width: 90px;
                text-align: center;
                position: absolute;
                top: 23px;
                left: 65px;
                transform: translateY(-50%);
                background-color: gray;
                color: #fff;
                padding: 4px 8px;
                font-size: 12px;
                opacity: 0;
                visibility: hidden;
                transition: opacity 1s ease, visibility 0.3s ease;
            }
            
            .Navbar-left .nav .navlink .icon-name:before {
                content: '';
                position: absolute;
                top: 10px;
                left: -3%;
                width: 10px;
                height: 10px;
                background-color: gray;
                transform: rotate(-45deg);
            }
            
            .Navbar-left .nav .navlink:hover .icon-name {
                opacity: 1;
                visibility: visible;
            }