.navbar {
    display: inline;
    float: left;
}
.routes {
    display: inline;
}
.container {
    display: grid;
    grid-template-columns: auto auto;
}
